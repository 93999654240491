const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://philippinelaw.jp/', //done
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'logo.png', //done
    logoLink:  'https://philippinelaw.jp/', //done
    title: "フィリピン法情報（弁護士　野田隼人）", //done
    githubUrl: 'https://github.com/nodahayato/plaw-gatsby', //done
    helpUrl: '',
    tweetText: '',
    social: `<li>
		    <a href="https://twitter.com/nodahayato" target="_blank" rel="noopener">
          <i class="fab fa-twitter-square"></i>
		    </a>
		  </li>`, //done snsまわり。画像がhasuraのサーバーになっているのでどうするか考える。フリーのsvgアイコン？
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [ // 何もかかないとアルファベティカル。ファイル名ナンバリングが簡単？
      '/NewPage', 
      '/introduction', // add trailing slash if enabled above
      '/codeblock',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [{ text: '高島法律事務所', link: 'https://locolo.jp' }], //左ペイン下部のリンク集っぽい
    frontline: true,
    ignoreIndex: true,
    title: //左ペインの上部にある題名ぽいところ。複数おけるのかな？
      "",
  },
  siteMetadata: {
    title: "フィリピン法情報（弁護士　野田隼人）", //done
    description: 'フィリピン法についての日本語情報です ', //done
    ogImage: null,
    docsLocation: 'https://github.com/nodahayato/plaw-gatsby', //done
    favicon: '', //TODO
  },
  pwa: { //TODO Progressive Web Appの設定周り。必要性考える。
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'フィリピン法情報（弁護士　野田隼人）', //done
      short_name: 'フィリピン法情報', //done
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials', //done htmlのcrossorigin属性
      icons: [
        {
          src: 'src/pwa-512.png', //TODO faviconと同じ感じのものが入ってるから直さないといけない気がする。
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
